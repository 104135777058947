import dateFormat from "@mobi/utils/formatters/date"
import format from "@mobi/utils/formatters/number"
import { getFromSafeStorage } from "utils/safeStorage"

export const DealFinishedDetail = async (dealFinished) => {
  const handleContracts = (contracts) => {
    if (!contracts) {
      return []
    }

    return contracts
  }

  let groupDebtBankAccounts
  try {
    groupDebtBankAccounts = await getFromSafeStorage("groupDebtBankAccounts")
  } catch (e) {
    groupDebtBankAccounts = []
  }

  return dealFinished.map(
    (
      {
        external_agreement_id = "",
        custom_data = {},
        due_date = "",
        agreement_value = "",
      },
      index
    ) => {
      if (!external_agreement_id || !Object.keys(custom_data).length)
        throw new Error("Error to get dealFinishedDetail custom_data")

      const feesNumber = custom_data.interest_rate_monthly
        ? Number(custom_data.interest_rate_monthly)
        : null
      const feesYearlyNumber = custom_data.interest_rate_yearly
        ? Number(custom_data.interest_rate_yearly)
        : null

      const cetNumber = custom_data.effective_cost
        ? Number(custom_data.effective_cost)
        : null
      const cetYearlyNumber = custom_data.effective_cost_yearly
        ? Number(custom_data.effective_cost_yearly)
        : null

      return {
        title: `Grupo ${index + 1}`,
        id: external_agreement_id,
        contracts: handleContracts(custom_data.contract_details),
        date: dateFormat(due_date),
        originalValue: format({
          value: custom_data.original_value,
          style: "currency",
        }).substring(3),
        currentValue: format({
          value: agreement_value,
          style: "currency",
        }).substring(3),
        currentValueRaw: Number(agreement_value),
        paymentMethod: custom_data.payment_method,
        otherInstallmentDates: custom_data.other_installments_due_day,
        installmentNumber: custom_data.installment_number,
        fees: feesNumber ? feesNumber?.toFixed(2) : null,
        fees_yearly: feesYearlyNumber ? feesYearlyNumber?.toFixed(2) : null,
        parc: format({
          value: custom_data.installment_value,
          style: "currency",
        }).substring(3),
        totalBalance: format({
          value: custom_data.total_balance,
          style: "currency",
        }).substring(3),
        cet: cetNumber ? cetNumber?.toFixed(2) : null,
        cet_yearly: cetYearlyNumber ? cetYearlyNumber?.toFixed(2) : null,
        iof: custom_data.iof_value
          ? custom_data.iof_value === "*"
            ? custom_data.iof_description
            : Number(custom_data.iof_value) || null
          : null,
        firstInstallmentPaymentMethod:
          custom_data.first_installment_payment_method || "boleto",
        otherInstallmentPaymentMethod:
          custom_data.other_installment_payment_method || "boleto",
        savedValue: format({
          value: custom_data.saved_value,
          style: "currency",
        }).substring(3),
        bankAccounts: groupDebtBankAccounts,
        barCodeAgreements: custom_data.barcode || "",
        pix: {
          chargeType: custom_data.pix?.charge_type,
          dueDate: dateFormat(custom_data.pix?.due_date),
          expiration: custom_data.pix?.expiration,
          code: custom_data.pix?.pix_code,
          qrCode: custom_data.pix?.qr_code_base_64,
        },
      }
    }
  )
}
