import storage from "utils/storage"
import isClient from "@mobi/utils/browser/isClient"
import router from "utils/router"

export const addNRPageView = async (pageName, customData = {}) => {
  const callNR = () => {
    try {
      if (!window.analytics || !window.analytics.user() || !window.newrelic)
        return

      const userAnonymized = window.analytics.user().anonymousId()
      if (window.newrelic) {
        console.log("NR PageAction", `PageView - ${pageName}`)

        window.newrelic.addPageAction(`PageView - ${pageName}`, {
          anonymous_id: userAnonymized,
          ...customData,
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  if (
    typeof window !== "undefined" &&
    window.newrelic &&
    window.analytics?.initialized
  ) {
    return callNR()
  }

  await new Promise((resolve) => {
    setTimeout(() => {
      callNR()
      resolve()
    }, 1500)
  })
}

export const trackCrossSell = async (location) => {
  console.log("NR - trackCrossSell")
  const startUrl = storage.session.getItem("startSourceURL") || location.href
  const url = new URL(startUrl)

  const utms = {
    utm_source: url.searchParams.get("utm_source"),
    utm_medium: url.searchParams.get("utm_medium"),
    utm_campaign: url.searchParams.get("utm_campaign"),
  }

  if (!utms.utm_medium) return

  try {
    window?.newrelic?.addPageAction("crossSell-referral", {
      path: location.pathname,
      ...utms,
    })

    console.log("NR - trackCrossSell - success")
  } catch (error) {
    console.error("NR - trackCrossSell - error", error)
  }
}

let hasInitialized = false

const setup = () => {
  return new Promise((resolve, reject) => {
    if (isClient()) {
      let attempts = 0

      const intervalId = setInterval(() => {
        const hasNewRelic = Boolean(window?.newrelic)
        const hasAnonymousId = Boolean(window?.analytics?.initialized)
        const canResolve = (hasNewRelic && hasAnonymousId) || hasInitialized

        if (canResolve) {
          clearInterval(intervalId)
          hasInitialized = true
          resolve()
        }

        attempts++
        if (attempts === 100) {
          clearInterval(intervalId)
          reject(new Error("NRL - Script load attempts exceeded"))
        }
      }, 100)
    } else
      reject(
        new Error(
          "NRL - Script loading is not supported in a non-client environment"
        )
      )
  })
}

export const trackNRPage = async ({
  pageName = "",
  eventType = "PageView",
  name = "",
  message = "",
}) => {
  try {
    await setup()

    const userAnonymized = window.analytics.user().anonymousId()

    const leadUUID = storage.session.getItem("lead_uuid") || "unknown"

    const pathName = isClient()
      ? window.location.pathname?.replaceAll("/", "")
      : "unknown"
    const currentPageName = pageName || pathName

    const currentHref = isClient() ? window.location.href : "unknown"

    const previousPage = isClient() ? window.previousPath : "unknown"

    const utmSource =
      storage.session.getItem("utm_source") ||
      router.getQueryString("utm_source") ||
      "unknown"

    console.log("NR trackNRPage", currentPageName)

    window.newrelic
      .interaction()
      .setName(currentPageName)
      .setAttribute("event_type", eventType)
      .setAttribute("anonymous_id", userAnonymized)
      .setAttribute("lead_uuid", leadUUID)
      .setAttribute("name", name)
      .setAttribute("message", message)
      .setAttribute("previousPage", previousPage)
      .setAttribute("origin", utmSource)
      .setAttribute("href", currentHref)
      .save()
  } catch (e) {
    console.error(e)
  }
}
