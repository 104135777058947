import isClient from "@mobi/utils/browser/isClient"

const trackDealStarted = () => {
  if (isClient()) {
    if (window.fbq) {
      window.fbq("trackCustom", "Deal_started", {})
    }
  }
}

const trackPurchase = ({ value }) => {
  if (isClient()) {
    if (window.fbq) {
      window.fbq("trackCustom", "Purchase-Front-End", {
        value: value,
        currency: "BRL",
        content_type: "product_group",
      })
    }
  }
}

export default {
  trackDealStarted,
  trackPurchase,
}
