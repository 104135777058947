import { ENV } from "gatsby-env-variables"
import storage from "utils/storage"

const FEATURE_TOGGLE_KEY = "featureToggleCachedValues"

export const changeFeatureToggleItem = (key, value) => {
  const cachedValues = storage.local.getItem(FEATURE_TOGGLE_KEY) || {}
  cachedValues[key] = value

  storage.local.setItem(FEATURE_TOGGLE_KEY, cachedValues)
}

export const featureToggleStatus = {
  cnpj: ["local", "development", "staging"],
  featureToggleWidgetActive: ["local", "development", "staging"],
  agreementsInvoiceButtons: ["local", "development", "staging", "production"],
  newTextOutScheduleAtive: ["local", "development", "staging"],
  recaptchaActive: ["staging", "production"],
  isInterbankDebtActive: ["local", "development", "staging", "production"],
  isPJAgreementsActive: ["local", "development", "staging"],
  isSkipperButtonActive: ["local", "development", "staging"],
  isDesenrolaActive: ["local", "development", "staging", "production"],
  isMyAgreementsActive: ["local", "development", "staging"],
}

const generateFeatureToggleObj = () => {
  const keys = Object.keys(featureToggleStatus)

  const getIfKeyIsActive = (acc, key) => ({
    ...acc,
    [key]: featureToggleStatus[key].some((label) => label === ENV),
  })

  return keys.reduce(getIfKeyIsActive, {})
}

const featureToggle = () => {
  const cachedValues = storage.local.getItem(FEATURE_TOGGLE_KEY) || {}
  const featureToggleObj = generateFeatureToggleObj()

  if (ENV === "production") {
    return featureToggleObj
  }

  return {
    ...featureToggleObj,
    ...cachedValues,
  }
}

export default featureToggle
