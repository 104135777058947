import { BUILD_ENV } from "gatsby-env-variables"

import { datadogRum } from "@datadog/browser-rum"

const init = () => {
  console.log("Datadog - starterd")
  datadogRum.init({
    applicationId: "ea491d1a-e432-49f1-91e2-fda830f8de1b",
    clientToken: "pubf4f300884b0081a63d648fc0fe1edbea",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.com",
    service: "jn6_reneg",
    env: BUILD_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  })
}

export default {
  init,
}
