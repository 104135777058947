import React, { createContext, useContext, useEffect, useState } from "react"
import Loader from "components/Loader"

const LayoutContext = createContext({})

export const useLayout = () => {
  const context = useContext(LayoutContext)

  if (!context) {
    throw new Error(
      `useLayout hook must be used within a 'LayoutProvider' component`
    )
  }

  return context
}

export const LayoutProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [orangeButtonTitle, setOrangeButtonTitle] = useState("Continuar")
  const [disableOrangeButton, setDisableOrangeButton] = useState(false)

  const [loaderText, setLoaderText] = useState({
    introText: "",
    mainText: "",
  })
  const [props, setProps] = useState({})

  useEffect(() => {
    if (loading) {
      setProps({ className: "content-loading" })
    } else {
      setProps({})
    }
  }, [loading])

  return (
    <LayoutContext.Provider
      value={{
        setLoading,
        setLoaderText,
        loading,
        orangeButtonTitle,
        setOrangeButtonTitle,
        disableOrangeButton,
        setDisableOrangeButton,
      }}
    >
      <>
        <Loader
          show={loading}
          introText={loaderText.introText}
          mainText={loaderText.mainText}
        />
        {React.cloneElement(<div>{children}</div>, props)}
      </>
    </LayoutContext.Provider>
  )
}
