import isClient from "@mobi/utils/browser/isClient"

/**
 * @param {string} name O nome do experimento que deverá ser verificado qual variante está ativa
 * @param {number} tries O número de tentativas que a função irá fazer para tentar pegar o valor da variante, ex: 10
 * @returns Retorna uma promise que resolve com a variante ativa do experimento
 */

export const getVWOVariant = ({ name, tries = 30, interval = 100 }) => {
  return new Promise((resolve, reject) => {
    const getVWOVariantInterval = setInterval(() => {
      try {
        if (!isClient()) return

        if (window.Cypress) {
          clearInterval(getVWOVariantInterval)
          resolve("")
          return
        }

        if (window.vwo_experiment) {
          clearInterval(getVWOVariantInterval)
          const variant = window.vwo_experiment[name] || ""

          resolve(variant)
        }

        if (tries !== null) {
          tries--

          if (tries <= 1) {
            clearInterval(getVWOVariantInterval)
            resolve("")
          }
        }
      } catch (error) {
        clearInterval(getVWOVariantInterval)

        reject(error)
      }
    }, interval)
  })
}
