import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react"
import { setHeaderToken } from "services"
import storage, { handleStorageError } from "utils/storage"
import isClient from "@mobi/utils/browser/isClient"
import { InstallmentSimulation, CashSimulation } from "entities/CashSimulation"
import { getFromSafeStorage, setInSafeStorage } from "utils/safeStorage"

const OfferContext = createContext({})

export const useOffer = () => {
  const context = useContext(OfferContext)

  if (!context) {
    throw new Error(
      `useOffer hook must be used within a 'OfferProvider' component`
    )
  }

  return context
}

export const OfferProvider = ({ children }) => {
  const [simulation, setSimulation] = useState()
  const [clientName, setClientName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [paymentOption, setPaymentOption] = useState("")
  const [hasDesenrolaOffer, setHasDesenrolaOffer] = useState(() => {
    const storagedValue = handleStorageError(
      storage.session.getItem("hasDesenrolaOffer"),
      false
    )

    return storagedValue
  })
  const [hasDesenrolaInstallmentOffer, setHasDesenrolaInstallmentOffer] =
    useState(() => {
      const storagedValue = handleStorageError(
        storage.session.getItem("hasDesenrolaInstallmentOffer"),
        false
      )

      return storagedValue
    })

  const tokenParam =
    isClient() && new URL(document.location).searchParams.get("token")

  setHeaderToken(storage.session.getItem("bearerToken") || tokenParam || "")

  useEffect(() => {
    const setClientNameAsync = async () => {
      const storagedClientName = await getFromSafeStorage("clientName")
      const storagedCompanyName = await getFromSafeStorage("companyName")

      setClientName(storagedClientName)
      setCompanyName(storagedCompanyName)
    }

    setClientNameAsync()
    setPaymentOption(storage.session.getItem("paymentOption"))

    storage.session.removeItem("optInSlugs")
    storage.session.removeItem("authOptInID")
  }, [])

  const leadType = storage.session.getItem("lead_type")

  const handleLeadTypeName = leadType === "PJ" ? companyName : clientName

  const handleSetInstallment = useCallback(
    (installment, { isHotDeal, selectedPaymentPlans }) => {
      const formatedInstallment = InstallmentSimulation(
        installment,
        isHotDeal,
        selectedPaymentPlans
      )
      storage.session.setItem("installmentSimulation", formatedInstallment)
      storage.session.setItem("hotDealSimulation", formatedInstallment)

      return formatedInstallment
    },
    []
  )

  const handleSetCash = useCallback((cash) => {
    const formatedCash = CashSimulation(cash)
    storage.session.setItem("cashSimulation", formatedCash)

    return formatedCash
  }, [])

  const handleSetSimulation = useCallback((simulation) => {
    storage.session.setItem("cashSimulation", simulation.cashSimulation)
    storage.session.setItem("delayedSimulation", simulation.delayedSimulation)
    storage.session.setItem(
      "installmentSimulation",
      simulation.installmentSimulation
    )

    if (simulation.hotDealSimulation) {
      storage.session.setItem("hotDealSimulation", simulation.hotDealSimulation)

      setSimulation({
        cashSimulation: simulation.cashSimulation,
        delayedSimulation: simulation.delayedSimulation,
        installmentSimulation: simulation.installmentSimulation,
        hotDealSimulation: simulation.hotDealSimulation,
      })
    }

    setSimulation({
      cashSimulation: simulation.cashSimulation,
      delayedSimulation: simulation.delayedSimulation,
      installmentSimulation: simulation.installmentSimulation,
    })
  }, [])

  const handleSetPaymentOption = useCallback((paymentOption) => {
    setPaymentOption(paymentOption)
    storage.session.setItem("paymentOption", paymentOption)
  }, [])

  const handleSetClientName = useCallback((name) => {
    if (!name || typeof name !== "string") return
    const firstName = name.split(" ")[0]

    setClientName(firstName)
    setInSafeStorage("clientName", firstName)
  }, [])

  const resetDesenrolaOffer = () => {
    setHasDesenrolaInstallmentOffer(false)
    setHasDesenrolaOffer(false)
  }

  return (
    <OfferContext.Provider
      value={{
        clientName,
        handleLeadTypeName,
        simulation,
        paymentOption,
        handleSetSimulation,
        handleSetPaymentOption,
        handleSetClientName,
        handleSetInstallment,
        handleSetCash,
        hasDesenrolaOffer,
        setHasDesenrolaOffer,
        hasDesenrolaInstallmentOffer,
        setHasDesenrolaInstallmentOffer,
        resetDesenrolaOffer,
      }}
    >
      {children}
    </OfferContext.Provider>
  )
}
