import { convertStringToNumber } from "utils/convertStringToNumber"
import storage from "utils/storage"
import { trackNRPage } from "utils/trackings/newRelicEvents"

export const calculateRoasPDD = (agreementValue = 0) => {
  try {
    const paymentOption = storage.session.getItem("paymentOption")
    const cashOptions = ["cashSimulation", "delayedSimulation"]
    const installmentOptions = ["installmentSimulation"]

    const simulation = storage.session.getItem(paymentOption)

    if (!simulation) return null

    const isCash = cashOptions.includes(paymentOption)
    const isInstallment = installmentOptions.includes(paymentOption)

    if (!isCash && !isInstallment) return null

    return getRoasPDD({ simulation, agreementValue, isCash })
  } catch (error) {
    console.error(error)

    trackNRPage({
      name: "RoasPDDError",
      pageName: "Conclusao",
      message: `calculateRoasPDD | ${error.stack || error}`,
      eventType: "PageError",
    })

    return null
  }
}

const getRoasPDD = ({ simulation, agreementValue, isCash }) => {
  const { debtsGroups } = simulation

  const contracts = isCash
    ? getCashContracts(debtsGroups)
    : getInstallmentContracts(debtsGroups)

  const averageContractsValue =
    calculateWeightedAverageContractsValue(contracts)

  if (averageContractsValue > 360) {
    return Number((0.2519 * agreementValue).toFixed(2))
  }

  const roasFactor =
    -5e-6 * Math.pow(averageContractsValue, 2) +
    0.0026 * averageContractsValue +
    0.0123

  return Number((roasFactor * agreementValue).toFixed(2))
}

const getCashContracts = (debtsGroups) => {
  const contracts = debtsGroups.reduce((acc, group) => {
    const contractsInfos = group.contracts.map(
      ({ currentValue, delayedDays }) => ({
        value: convertStringToNumber(currentValue),
        delayedDays,
      })
    )

    return [...acc, ...contractsInfos]
  }, [])

  return contracts
}

const getInstallmentContracts = (debtsGroups) => {
  const contracts = debtsGroups.reduce((acc, group) => {
    const {
      selectedPaymentPlan: { totalValue, totalDebtValue },
    } = group

    const rawTotalValue = convertStringToNumber(totalValue)
    const rawTotalDebtValue = convertStringToNumber(totalDebtValue)

    const groupPaymentPercentage = rawTotalValue / rawTotalDebtValue

    const contractsInfos = group.contracts.map(
      ({ delayedDays, simulationInfo: { DebtValue: originalDebtValue } }) => ({
        value: originalDebtValue * groupPaymentPercentage,
        delayedDays,
      })
    )

    return [...acc, ...contractsInfos]
  }, [])

  return contracts
}

const calculateWeightedAverageContractsValue = (contracts) => {
  const agreementValue = contracts.reduce((acc, { value }) => acc + value, 0)

  return (
    contracts.reduce(
      (acc, { value, delayedDays }) => acc + value * delayedDays,
      0
    ) / agreementValue
  )
}
