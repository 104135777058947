import { configure } from "@mobi/ds"
import analytics from "@mobi/libraries/analytics"
import trackings from "./src/utils/trackings"
import { SEGMENT_ID } from "gatsby-env-variables"
import { trackCrossSell } from "./src/utils/trackings/newRelicEvents"
import services from "./src/services"

import Root from "./src/Root"

configure({
  originFonts: "extern",
  fonts: {
    ItauDisplay: [900],
    ItauText: [400, 700],
  },
  setStyleVariablesInHTML: false,
})

services.datadog.init()

// analytics.setup expects a "makeTagular" object. Without it the whole app breaks
analytics.setup({
  GTMId: "GTM-WLR7NT3",
  isSDKTrackings: true,
  segmentId: SEGMENT_ID,
  makeTagular: {},
  trackings,
})

export const onClientEntry = () => {
  window.addEventListener("load", () => {
    document.body.className = document.body.className.replace(
      /\brendering-body\b/,
      ""
    )
  })
}

export const onRouteUpdate = ({ location }) => {
  window.locations = window.locations || [document.referrer]
  locations.push(window.location.href)
  window.previousPath = locations[locations.length - 2]

  trackCrossSell(location)
}

export const wrapRootElement = Root
