import { DealFinishedDetail } from "entities/DealFinished"
import { createContext, useContext, useState, useCallback } from "react"
import storage from "utils/storage"

const ConclusionContext = createContext({})

export const useConclusion = () => {
  const context = useContext(ConclusionContext)

  if (!context) {
    throw new Error(
      `useConclusion hook must be used within a 'ConclusionProvider' component`
    )
  }

  return context
}

export const ConclusionProvider = ({ children }) => {
  const [dealFinished, setDealFinished] = useState(
    storage.session.getItem("dealFinished") || []
  )

  const handleDealFinished = useCallback(async (dealFinished) => {
    const dealFineshedDetail = await DealFinishedDetail(dealFinished)

    setDealFinished(dealFineshedDetail)
    storage.session.setItem("dealFinished", dealFineshedDetail)

    return dealFineshedDetail
  }, [])

  return (
    <ConclusionContext.Provider
      value={{
        dealFinished,
        handleDealFinished,
        setDealFinished,
      }}
    >
      {children}
    </ConclusionContext.Provider>
  )
}
